import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Gallery from "../components/gallery"

import vincentPricePhoto from "../../content/gallery/Vincent Price.jpg"
import billCoganPhoto from "../../content/about/Bill Cogan.jpg"
import hollywoodMusicianPhoto from "../../content/about/Hollywood Musician, 1947.jpg"
import laverneCoganPhoto from "../../content/about/LaVerne Cogan.jpg"
import manWithCigarettePhoto from "../../content/about/Man with Cigarette.jpg"
import GalleryItem from "../components/galleryItem"

const images = [
  {
    id: "hollywoodMusicianPhoto",
    publicURL: hollywoodMusicianPhoto,
    name: "Hollywood Musician, 1947",
    fluid: { src: hollywoodMusicianPhoto },
  },
  {
    id: "manWithCigarettePhoto",
    publicURL: manWithCigarettePhoto,
    name: "Man with Cigarette",
    fluid: { src: manWithCigarettePhoto },
  },
  {
    id: "billCoganPhoto",
    publicURL: billCoganPhoto,
    name: "Bill Cogan",
    fluid: { src: billCoganPhoto },
  },
  {
    id: "laverneCoganPhoto",
    publicURL: laverneCoganPhoto,
    name: "LaVerne Cogan",
    fluid: { src: laverneCoganPhoto },
  },
]

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <h3 style={{ margin: `0 0 0.45rem 0` }}>About Bill Cogan</h3>
      <p style={{ margin: `0 0 3rem 0` }}>January 4, 2020</p>
      <p>
        Bill Cogan was born 100 years ago in Brooklyn, New York. During the
        Great Depression when he was in his teens, he would often wander through
        the free museums in Manhattan, studying the Rembrandts, which he was
        particularly drawn to. He went to photography school at the beginning of
        the 40s and worked for a time as an assistant at a fashion photography
        house.
      </p>
      <p>
        In the mid-40s when his younger brother, Lionel, suddenly decided to
        take off on a trip to California, Bill went along to chaperone. Lionel
        got to Southern California, took one look around, and was ready to
        return home. Bill was hooked on Hollywood.
      </p>
      <div>
        <img
          src={vincentPricePhoto}
          style={{
            // maxWidth: "50vw",
            width: "50%",
            float: "right",
            margin: `0 0 2rem 2rem`,
          }}
        />
        <p>
          He found many aspiring actors in need of head shots, and his style of
          theatrical photography was perfectly suited to the decade. In
          Hollywood in the 40s, known and unknown actors could be found just
          walking down the street. One day Bill ran into Vincent Price leaving a
          stage show in makeup, and Bill asked him to pose. This photo of
          Vincent Price is one of the earliest Bill took in Hollywood.
        </p>
        <p>
          Bill started a family, taking a day job in 1947 as lighting director
          at the new television station at KLAC in Hollywood, where Betty White
          and Leonard Nimoy also worked behind the scenes. In the early 50s,
          Bill and his wife and baby daughter (a son would follow) relocated to
          San Francisco, where he specialized in photographing performers at the
          SF Ballet, the SF Symphony and the SF Opera.
        </p>
        <p>
          Because of Bill’s Hollywood background, he also worked as a freelance
          photographer, and ended up at events for movie actors passing through
          the City. The collection presented here is a sample of some of his
          favorite actors, which he printed in his darkroom before his passing
          at the end of 2009.
        </p>
      </div>
      <Gallery images={images} gridSize={"large"} sort={false} />
    </Layout>
  )
}

export default AboutPage
